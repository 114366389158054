import React, { useEffect, useState, useMemo } from "react";
import {
  Box,
  Typography,
  Button,
  MenuItem,
  Select,
  FormControl,
  InputLabel,
  Grid,
  Alert,
} from "@mui/material";
import { ZoomOutMap, ImageSearch } from "@mui/icons-material";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { DEVICE_DETAILED_STATUSES, statusToReadable } from "utils/device-statuses";
import { useAuth } from "context/AuthProvider";
import { LoadingButton } from "@mui/lab";
import { ImageView } from "components/Image/ImageView";
import { IMAGE_TYPES, listImages, ListImagesParams } from "queries/devices/images";
import { useQuery } from "@tanstack/react-query";
import { updateValidatorDeviceDetails } from "queries/validators";
import { useDeviceInfoPanel } from "./useDeviceInfoPanel";
import { useDeviceStatus } from "../useDeviceStatus";
import PendingImagesAlert from "./PendingImagesAlert";
import StatusChangeApprovalAlert from "./StatusChangeApprovalAlert";
import { FullImageModal } from "../FullImageModal";
import { ReactComponent as MothZoomIcon } from "../../../../icons/moth_zoom.svg";

const styles = {
  container: {
    boxShadow: 2,
    borderRadius: "10px",
    padding: "16px",
    bgcolor: "white",
  },
  image: {
    width: "100%",
    aspectRatio: "4/3",
    borderRadius: "18px",
    objectFit: "cover",
    objectPosition: "center",
  },
  buttonContainer: {
    display: "flex",
    justifyContent: "space-between",
    marginTop: "16px",
    gap: "16px",
  },
  imageContainer: {
    width: "55%",
    marginBottom: "8px",
    position: "relative",
    cursor: "pointer",
  },
  modalImage: {
    maxWidth: "90%",
    maxHeight: "90%",
    objectFit: "contain",
  },
  zoomIcon: {
    position: "absolute",
    bottom: "16px",
    right: "8px",
    color: "#F4B71D",
    backgroundColor: "rgba(0, 0, 0, 0.5)",
    borderRadius: "50%",
    padding: "4px",
  },
} as const;

interface DeviceInfoProps {
  deviceId: string;
  listNotValidatedImagesLoading: boolean;
  listNotValidatedImagesCount: number;
  onFullPictureValidation: () => void;
  onValidationDateChange: () => void;
}

const DeviceInfoPanel: React.FC<DeviceInfoProps> = ({
  deviceId,
  listNotValidatedImagesLoading,
  listNotValidatedImagesCount,
  onFullPictureValidation,
  onValidationDateChange,
}) => {
  const { isAdmin } = useAuth();
  const {
    deviceDetail,
    loading,
    error,
    reloadDeviceDetails,
    validationStartDate,
    setValidationStartDate,
  } = useDeviceInfoPanel(deviceId);

  const {
    selectedStatus,
    setSelectedStatus,
    handleDropDownStatusChange,
    isStatusChanged,
    handleUpdateStatusClick,
    handleApproveStatusClick,
  } = useDeviceStatus(deviceId, reloadDeviceDetails);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [imageSrc, setImageSrc] = useState<string>(
    "https://scoutlabs.ag/wp-content/uploads/2024/06/scoutlabs-insects-v2.jpg"
  );

  const lastImageParams: ListImagesParams = useMemo(
    () => ({
      device__id: deviceId,
      page_size: 1,
      page: 1,
      ordering: "-created_at",
    }),
    [deviceId]
  );

  const getLastImageID = useQuery({
    queryKey: ["list-all-images", deviceId],
    queryFn: async () => (await listImages(lastImageParams)).data[0].id,
    onError: (e) => {
      console.error("Failed to get the last image: ", e);
    },
    enabled: !!deviceId,
  });

  const updateValidationStartDate = async (newDate: number | Date) => {
    await updateValidatorDeviceDetails({
      id: deviceId,
      validation_start_date: new Date(newDate).getTime() / 1000,
    });

    reloadDeviceDetails();
    onValidationDateChange();
  };

  const handleOpenModal = () => setIsModalOpen(true);
  const handleCloseModal = () => setIsModalOpen(false);

  const toKebabCase = (text: string) => {
    return text
      .toLowerCase()
      .split(/[^\w]+/)
      .slice(0, 2)
      .join("-");
  };

  const loadImageDynamically = async (imageName: string): Promise<string> => {
    try {
      const image = await import(`pictures/${imageName}.jpeg`);
      return image.default;
    } catch (err) {
      console.error("Image not found, using fallback:", err);
      return "https://scoutlabs.ag/wp-content/uploads/2024/06/scoutlabs-insects-v2.jpg";
    }
  };

  const handleValidationStartChange = (date: Date | null): void => {
    if (date) {
      setValidationStartDate(date);
      updateValidationStartDate(date);
    }
  };

  useEffect(() => {
    const fetchImage = async () => {
      if (deviceDetail?.pest_name) {
        const kebabName = toKebabCase(deviceDetail.pest_name);
        const imagePath = await loadImageDynamically(kebabName);
        setImageSrc(imagePath);
      } else {
        setImageSrc("https://scoutlabs.ag/wp-content/uploads/2024/06/scoutlabs-insects-v2.jpg");
      }
    };

    fetchImage();
  }, [deviceDetail]);

  useEffect(() => {
    if (deviceDetail?.status) {
      setSelectedStatus(
        deviceDetail.status_needs_approval
          ? deviceDetail.last_not_approved_status!
          : deviceDetail.status
      );
    }
  }, [deviceDetail, setSelectedStatus]);

  if (loading) {
    return <Typography variant="h6">Loading...</Typography>;
  }

  if (error && error instanceof Error) {
    return <Alert severity="error">{error.message}</Alert>;
  }

  return (
    <Grid item xs={12} sm={12} md={5}>
      <Box sx={styles.container}>
        <Typography variant="h4" sx={{ fontWeight: "bold" }}>
          {deviceDetail!.smapp_id}
        </Typography>
        {isAdmin && deviceDetail!.validator_username && (
          <Typography
            variant="body1"
            gutterBottom
            sx={{ fontWeight: "bold", marginBottom: "16px" }}
          >
            {deviceDetail!.validator_username}
          </Typography>
        )}
        <Box onClick={handleOpenModal} sx={styles.imageContainer}>
          <img src={imageSrc} alt="Pest" style={styles.image} />
          <ZoomOutMap sx={styles.zoomIcon} />
        </Box>
        <Typography variant="body1" gutterBottom>
          {deviceDetail!.pest_name}
        </Typography>

        {isAdmin && deviceDetail!.status_needs_approval && (
          <>
            <StatusChangeApprovalAlert
              status={deviceDetail!.status}
              lastNotApprovedStatus={deviceDetail!.last_not_approved_status!}
              statusNeedsApproval={deviceDetail!.status_needs_approval}
              onApproveStatus={handleApproveStatusClick}
            />
            {getLastImageID.isSuccess && getLastImageID.data && (
              <ImageView id={getLastImageID.data} type={IMAGE_TYPES.raw} stretchy />
            )}
          </>
        )}

        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            gap: { xs: "8px", sm: "8px", md: "16px" },
          }}
        >
          <FormControl
            fullWidth
            size="small"
            sx={{
              marginBottom: "15px",
              marginTop: "32px",
              width: { xs: "60%", sm: "50%", md: "40%" },
            }}
          >
            <InputLabel id="newStatus">Status</InputLabel>
            <Select
              labelId="newStatus"
              value={selectedStatus}
              label="New Status"
              onChange={handleDropDownStatusChange}
              sx={{ minWidth: { xs: "120px", sm: "150px", md: "120px" } }}
            >
              {DEVICE_DETAILED_STATUSES.map((value) => (
                <MenuItem key={value} value={value}>
                  {statusToReadable(value)}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          <Button
            variant="contained"
            sx={{
              flexShrink: 2,
              padding: { xs: "6px 12px", sm: "6px 12px" },
            }}
            onClick={handleUpdateStatusClick}
            disabled={!isStatusChanged}
          >
            UPDATE STATUS
          </Button>
        </Box>
        <FormControl
          fullWidth
          size="small"
          sx={{
            marginBottom: "0.2em",
            width: "100%",
          }}
        >
          <DatePicker
            label="Validation start date"
            minDate={new Date("2020-01-01")}
            value={validationStartDate}
            onChange={handleValidationStartChange}
            slotProps={{ textField: { size: "small" } }}
          />
        </FormControl>

        <PendingImagesAlert pendingImages={deviceDetail!.unvalidated_and_visible_image_count} />

        {/* Action Buttons */}
        <Box sx={styles.buttonContainer}>
          <Button
            variant="contained"
            startIcon={<MothZoomIcon style={{ width: 24, height: 24 }} />}
            disabled
          >
            Pest Zoom validation
          </Button>
          <LoadingButton
            variant="contained"
            startIcon={<ImageSearch style={{ width: 24, height: 24 }} />}
            onClick={onFullPictureValidation}
            disabled={deviceDetail!.unvalidated_and_visible_image_count === 0}
            loading={listNotValidatedImagesLoading}
          >
            Full Picture validation
          </LoadingButton>
        </Box>
        <FullImageModal open={isModalOpen} onClose={handleCloseModal} imageUrl={imageSrc} />
      </Box>
    </Grid>
  );
};

export default DeviceInfoPanel;
