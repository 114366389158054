import React from "react";
import AppWrapper from "components/AppWrapper";
import { Box, Checkbox, FormControlLabel, TextField } from "@mui/material";
import { useAuth } from "context/AuthProvider";
import { debounce } from "lodash";
import useDeviceFilters from "hooks/useDeviceFilters";
import DeviceFiltersDrawer from "./DeviceFiltersDrawer";
import DeviceFiltersFab from "./DeviceFiltersFab";
import DevicesList from "./DevicesList";
import DeviceGeneralStatusFilterChips from "./DeviceStatusFilterChips";

const DeviceMain: React.FC = () => {
  const { isAdmin } = useAuth();
  const [search, setSearch] = React.useState(sessionStorage.getItem('search') || "");
  const [archived, setArchived] = React.useState(true);

  const onSearchChanged = React.useMemo(
    () =>
      debounce(({ target: { value } }: React.ChangeEvent<HTMLInputElement>) => {
        setSearch(value);
        sessionStorage.setItem('search', value);
      }, 600),
    []
  );

  React.useEffect(() => {
    return () => {
      onSearchChanged.cancel();
    };
  }, [onSearchChanged]);

  return (
    <AppWrapper>
      <Box sx={{ display: "flex", flexFlow: "row" }}>
        <TextField
          sx={{ m: 1.5, width: "66%" }}
          label="Search"
          variant="standard"
          onChange={onSearchChanged}
          defaultValue={search}
        />
        {isAdmin && (
          <FormControlLabel
            sx={{ marginLeft: "auto" }}
            control={
              <Checkbox
                checked={archived}
                onChange={(e) => {
                  setArchived(e.target.checked);
                }}
              />
            }
            label="Hide archived devices"
          />
        )}
      </Box>

      <DeviceGeneralStatusFilterChips />

      <DevicesList search={search} archived={archived} />
      <DeviceFiltersFab />
      <DeviceFiltersDrawer />
    </AppWrapper>
  );
};

export default DeviceMain;
