import React from "react";
import { useInfiniteQuery } from "@tanstack/react-query";
import { Box, Stack, Typography } from "@mui/material";
import { ListDeviceParams, listDevices } from "queries/devices";
import { FlexLoader, Loader } from "components/Loader";
import { flatten } from "lodash";
import { listAllMeasurements } from "queries/devices/measurements";
import { listAllPests } from "queries/pests";
import { listAllOneSevenDayDcd } from "queries/devices/averageDetectionCounts";
import DeviceCard, { DeviceCardProps } from "components/DeviceCard";
import { resolveKey, fetchResourceByIds, customFetchResource } from "utils/query";
import { listAllCrops } from "queries/crops";
import useInfiniteScroll from "react-infinite-scroll-hook";
import useDeviceFilters from "hooks/useDeviceFilters";
import { listAllNetworkDiagnostics } from "queries/devices/networkDiagnostics";
import { listAllCommissionStatuses } from "queries/devices/commissionStatus";
import { listAllFirmwares } from "queries/firmwares";
import { listAllConfigs } from "queries/configs";
import { generalStatusesToDetailedStatuses } from "utils/device-statuses";
import { listAllGeolocations } from "queries/devices/geolocations";

const INFINITE_PAGE_SIZE = 20;

interface Props {
  // show also archived devices
  archived: boolean;
  search: string | null;
}

function DevicesList({ archived, search }: Props) {
  const { nonEmptyFilters, activeStatuses } = useDeviceFilters();

  const {
    data: infData,
    isLoading,
    error,
    fetchNextPage,
    hasNextPage,
    isFetchingNextPage,
    isRefetching,
  } = useInfiniteQuery({
    queryKey: ["inf-list-devices", nonEmptyFilters, archived, activeStatuses, search],
    queryFn: async ({ pageParam = 1 }) => {
      const listDeviceParams: ListDeviceParams = {
        page: pageParam,
        page_size: INFINITE_PAGE_SIZE,
        ...nonEmptyFilters,
      };

      if (activeStatuses) {
        listDeviceParams.status__in = generalStatusesToDetailedStatuses(activeStatuses);
      }
      if (archived) {
        listDeviceParams.active = archived;
      }
      if (search != null && search.length > 0) {
        listDeviceParams.search = search;
      }

      const { data: devices, meta } = await listDevices(listDeviceParams);

      const [
        measurementsById,
        pestsById,
        cropsById,
        firmwaresById,
        configsById,
        commissionStatusById,
        networkDiagnosticsById,
        geolocationsById,
        dayAndWeekDcDcd,
      ] = await Promise.all([
        fetchResourceByIds(
          devices.map((it) => it.last_measurement),
          listAllMeasurements
        ),
        fetchResourceByIds(
          devices.map((it) => it.current_pest),
          listAllPests
        ),
        fetchResourceByIds(
          devices.map((it) => it.current_crop),
          listAllCrops
        ),
        fetchResourceByIds(
          devices.map((it) => it.current_firmware),
          listAllFirmwares
        ),
        fetchResourceByIds(
          devices.map((it) => it.current_config),
          listAllConfigs
        ),
        fetchResourceByIds(
          devices.map((it) => it.current_commission_status),
          listAllCommissionStatuses
        ),
        fetchResourceByIds(
          devices.map((it) => it.last_network_diagnostic),
          listAllNetworkDiagnostics
        ),
        fetchResourceByIds(
          devices.map((it) => it.last_geo_point),
          listAllGeolocations
        ),
        customFetchResource(() => {
          return listAllOneSevenDayDcd({
            device_ids: devices.map((v) => v.id),
          });
        }),
      ]);

      const data: DeviceCardProps[] = devices.map((it) => {
        return {
          ...it,
          current_pest: resolveKey(it.current_pest, pestsById),
          current_crop: resolveKey(it.current_crop, cropsById),
          last_measurement: resolveKey(it.last_measurement, measurementsById),
          last_network_diagnostic: resolveKey(it.last_network_diagnostic, networkDiagnosticsById),
          current_commission_status: resolveKey(it.current_commission_status, commissionStatusById),
          current_firmware: resolveKey(it.current_firmware, firmwaresById),
          current_config: resolveKey(it.current_config, configsById),
          last_geo_point: resolveKey(it.last_geo_point, geolocationsById),
          one_and_seven_day_dc_dcd: resolveKey(it.id, dayAndWeekDcDcd),
        };
      });
      return { data, meta };
    },
    getNextPageParam: (lastPage) => (lastPage.meta.next ? lastPage.meta.page + 1 : undefined),
    refetchOnWindowFocus: false,
    refetchOnReconnect: false,
    refetchOnMount: false,
    keepPreviousData: true,
  });

  const data = React.useMemo(
    () => flatten((infData?.pages ?? [])?.map((it) => it.data)),
    [infData]
  );

  const [scrollRef] = useInfiniteScroll({
    loading: isFetchingNextPage,
    hasNextPage: !!hasNextPage,
    onLoadMore: fetchNextPage,
    disabled: !!error,
    rootMargin: "0px 0px 1000px 0px",
  });

  const count = infData?.pages[0].meta.count;

  if (isLoading || isRefetching) {
    return <FlexLoader />;
  }

  if (data) {
    return (
      <Box>
        {count === 0 && (
          <Typography align="center" variant="body2">
            No devices matching the criteria.
          </Typography>
        )}

        <Stack spacing={2}>
          {data.map((it) => (
            <DeviceCard key={it.id} {...it} ref={scrollRef} />
          ))}
        </Stack>
        {hasNextPage && isFetchingNextPage && (
          <Box
            sx={{
              my: 6,
              display: "flex",
              width: "100%",
              justifyContent: "center",
            }}
          >
            <Loader />
          </Box>
        )}
      </Box>
    );
  }
  return <Typography sx={{ px: 2, py: 2 }}>{String(error)}</Typography>;
}

export default DevicesList;
