import React from "react";
import { Alert, AlertColor, Dialog, Snackbar, Typography } from "@mui/material";
import { useQuery } from "@tanstack/react-query";
import { detailDevice } from "queries/devices";
import { detailConfig } from "queries/configs";
import { detailCrop } from "queries/crops";
import { detailCommissionStatus } from "queries/devices/commissionStatus";
import { detailPest } from "queries/pests";
import { detailFirmware } from "queries/firmwares";
import useBatchQueries from "./useBatchQueries";

import UpdateDeviceSettingsDialogTitle from "./Title";
import UpdateDeviceSettingsDialogForm from "./Form";
import UpdateDeviceSettingsDialogFormLoader from "./FormLoader";
import { UpdateDeviceSettingsFormValues } from "./types";

type UpdateDeviceSettingsDialogProps = {
  id?: string;
  isOpen: boolean;
  onClose: () => void;
};

const UpdateDeviceSettingsDialog: React.FC<UpdateDeviceSettingsDialogProps> = ({
  id,
  isOpen,
  onClose,
}) => {
  const [openSnackBar, setOpenSnackBar] = React.useState(false);
  const [status, setStatus] = React.useState({ type: "info", message: "" });

  const deviceQuery = useQuery({
    queryKey: ["detail-device", { id }],
    queryFn: () => detailDevice({ id: id as string }),
    enabled: !!id,
  });

  const deviceId = deviceQuery.data?.id;

  const initialValues: UpdateDeviceSettingsFormValues | undefined = React.useMemo(() => {


    if (deviceQuery.data) {
      const initialValues: UpdateDeviceSettingsFormValues = {
        target_lure_type: deviceQuery.data.lure_type ?? null,
        target_lure_days_life_span: deviceQuery.data.lure_days_life_span ?? null,
        target_lure_last_replaced: deviceQuery.data.lure_last_replaced ?? null,

        target_pest: deviceQuery.data.current_pest ?? null,
        target_crop: deviceQuery.data.current_crop ?? null,
        target_config: deviceQuery.data.target_config ?? null,
        target_commission_status: deviceQuery.data.current_commission_status ?? null,
        target_firmware: deviceQuery.data.target_firmware ?? null,
      };
      return initialValues;

    }
    return undefined;
  }, [deviceQuery.data]);

  const batchQuery = useBatchQueries({
    queriesData: [
      { qkID: deviceQuery.data?.current_pest, qk: "Pest", qf: detailPest },
      { qkID: deviceQuery.data?.current_crop, qk: "Crop", qf: detailCrop },
      { qkID: deviceQuery.data?.target_config, qk: "Config", qf: detailConfig },
      {
        qkID: deviceQuery.data?.current_commission_status,
        qk: "CommissionStatus",
        qf: detailCommissionStatus,
      },
      {
        qkID: deviceQuery.data?.target_firmware,
        qk: "Firmware",
        qf: detailFirmware,
      },
    ],
  });

  const comissionLabel = batchQuery.find((vv) => vv.name === "CommissionStatus")?.label;
  if (comissionLabel && initialValues) initialValues.target_commission_status = comissionLabel;

  let content = null;
  if (deviceQuery.error) {
    content = <Typography>{String(deviceQuery.error)}</Typography>;
  } else if (deviceId && initialValues) {
    content = (
      <UpdateDeviceSettingsDialogForm
        handleSuccess={() => {
          onClose();
          deviceQuery.refetch();
          setOpenSnackBar(true);
          setStatus({ type: "success", message: "Settings save succeed!" });
        }}
        handleError={(error: Error) => {
          onClose();
          setOpenSnackBar(true);
          setStatus({ type: "error", message: error.message });
        }}
        initialValues={initialValues}
        id={deviceId}
        actionsProps={{ handleCancelClick: onClose }}
        batchQuery={batchQuery}
      />
    );
  } else {
    content = (
      <UpdateDeviceSettingsDialogFormLoader actionsProps={{ handleCancelClick: onClose }} />
    );
  }

  return (
    <>
      <Snackbar
        open={openSnackBar}
        autoHideDuration={2000}
        onClose={() => setOpenSnackBar(false)}
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
      >
        <Alert
          variant="filled"
          severity={status.type as AlertColor}
          sx={{ width: "100%" }}
          onClose={() => setOpenSnackBar(false)}
        >
          {status.message}
        </Alert>
      </Snackbar>
      <Dialog open={isOpen} onClose={onClose} maxWidth="sm">
        <UpdateDeviceSettingsDialogTitle deviceQuery={deviceQuery} onClose={onClose} />
        {content}
      </Dialog>
    </>
  );
};

export default UpdateDeviceSettingsDialog;
