import React from "react";
import { Grid } from "@mui/material";
import DeviceInfoPanel from "./DeviceInfoPanel/DeviceInfoPanel";
import ImageGridPanel from "./ImageGridPanel/ImageGridPanel";
import ValidationModal from "./ValidationModal/ValidationModal";
import { useValidatorValidation } from "./useValidatorValidation";

type ValidatorDeviceDetailProps = {
  id: string;
};

const ValidatorDeviceDetailMain: React.FC<ValidatorDeviceDetailProps> = ({ id }) => {
  const {
    validatingImages,
    validationModelOpen,
    listNotValidatedImagesLoading,
    listNotValidatedImagesCount,
    handleStartValidation,
    handleStopValidation,
    handleFullPictureValidation,
    handleValidationDateChanged
  } = useValidatorValidation(id);

  return (
    <Grid container spacing={3} sx={{ padding: "24px" }}>
      <DeviceInfoPanel
        deviceId={id}
        onFullPictureValidation={handleFullPictureValidation}
        listNotValidatedImagesLoading={listNotValidatedImagesLoading}
        listNotValidatedImagesCount={listNotValidatedImagesCount}
        onValidationDateChange={handleValidationDateChanged}
      />
      <ImageGridPanel deviceID={id} onStartValidation={handleStartValidation} />
      <ValidationModal
        deviceID={id}
        imagesArray={validatingImages}
        isOpen={validationModelOpen}
        onClose={handleStopValidation}
      />
    </Grid>
  );
};

export default ValidatorDeviceDetailMain;
