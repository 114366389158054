import React from "react";
import { Box, Button, IconButton } from "@mui/material";
import { useValidation } from "context/ValidationProvider";
import { useChainValidation } from "context/ChainValidationProvider";

import { Close, Image, Visibility, VisibilityOff } from "@mui/icons-material";

export type ToolbarTopProps = {
  timestamp?: number;
};

const ToolbarTop: React.FC<ToolbarTopProps> = ({timestamp}) => {
  const { onClose, onChangeVisibility, isVisible } = useValidation();
  const { isChainValidation, currrentImageIndex, allImageCount } = useChainValidation();

  return (
    <Box>
      <Box sx={{ display: "flex", alignItems: "center", justifyContent: "space-between" }}>
        <Box>
          {isVisible ?? true ? (
            <Button variant="outlined" size="small" sx={{ m: 1 }} onClick={onChangeVisibility}>
              REPORT NOT VISIBLE <VisibilityOff sx={{ ml: 1 }} />
            </Button>
          ) : (
            <Button variant="outlined" size="small" sx={{ m: 1 }} onClick={onChangeVisibility}>
              REMOVE VISIBILITY REPORT <Visibility sx={{ ml: 1 }} />
            </Button>
          )}
        </Box>

        {isChainValidation && (
          <Box sx={{ display: "flex", alignItems: "center" }}>
            <Image sx={{ mr: 0.5 }} /> {currrentImageIndex + 1} / {allImageCount}
          </Box>
        )}

        <IconButton aria-label="close" size="small" color="inherit" onClick={onClose}>
          <Close />
        </IconButton>
      </Box>
      <Box sx={{ display: "flex", justifyContent: "center", padding: "5px" }}>
        {timestamp ?? true ? (
        <Box sx={{ fontSize: "1rem" }}>{new Date(timestamp! * 1000).toLocaleString("en-US")}</Box>
        ) : (
          <Box sx={{ fontSize: "1rem" }}>No timestamp</Box>
        )}
      </Box>
    </Box>
  );
};

export default ToolbarTop;
