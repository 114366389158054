import React, { useState } from "react";
import { Box, Button, Typography } from "@mui/material";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import useCustomSnackBar from "hooks/useCustomSnackBar";
import UserRegistrationModal from "../components/UserRegistrationModal";
import useUserManagement from "./useUserManagement";
import UserTable from "../components/UserTable";
import EditUserModal from "../components/EditUserModal";
import ResetPasswordModal from "../components/ResetPasswordModal";

const UserManagementMain = () => {
  const { jsx: theSnack } = useCustomSnackBar();
  const {
    methods,
    onSubmit,
    onNameChange,
    listQuery,
    processedData,
    loadMore,
    totalCount,
    corporations,
    fetchNextPage,
    hasNextPage,
    isFetchingNextPage,
    setSearchTerm,
    onUpdateSubmit,
    onResetPasswordSubmit,
    openRegistrationModal,
    openEditModal,
    openUserPasswordResetModal,
    handleOpenRegistrationModal,
    handleCloseRegistrationModal,
    handleEditUser,
    handleCloseEditModal,
    handleUserPasswordReset,
    handleCloseUserPasswordResetModal,
    updateError,
    registerError,
    resetPasswordError,
    showDeleteConfirm,
    handleDeleteClick,
    handleConfirmDelete,
    handleCancelDelete,
    isDeleting,
    userToDelete,
  } = useUserManagement();

  return (
    <Box>
      {theSnack}
      <Box sx={{ p: 1, display: "flex", justifyContent: "space-between", alignItems: "center" }}>
        <Typography variant="h4" sx={{ mb: 4 }}>
          User Management
        </Typography>
        <Button
          variant="contained"
          endIcon={<AddCircleOutlineIcon />}
          onClick={handleOpenRegistrationModal}
        >
          Add New User
        </Button>
      </Box>
      <Box>
        <UserTable
          onNameChange={onNameChange}
          listQuery={listQuery}
          processedData={processedData}
          onLoadMore={loadMore}
          totalCount={totalCount}
          onEditUser={handleEditUser}
          onUserPasswordReset={handleUserPasswordReset}
          handleDeleteClick={handleDeleteClick}
          showDeleteConfirm={showDeleteConfirm}
          handleConfirmDelete={handleConfirmDelete}
          handleCancelDelete={handleCancelDelete}
          isDeleting={isDeleting}
          userToDelete={userToDelete}
        />
      </Box>

      <UserRegistrationModal
        open={openRegistrationModal}
        onClose={handleCloseRegistrationModal}
        methods={methods}
        onSubmit={onSubmit}
        corporations={corporations}
        fetchNextPage={fetchNextPage}
        hasNextPage={hasNextPage ?? false}
        isFetchingNextPage={isFetchingNextPage}
        setSearchTerm={setSearchTerm}
        registerError={registerError}
      />

      <EditUserModal
        open={openEditModal}
        onClose={handleCloseEditModal}
        methods={methods}
        onSubmit={onUpdateSubmit}
        corporations={corporations}
        fetchNextPage={fetchNextPage}
        hasNextPage={hasNextPage ?? false}
        isFetchingNextPage={isFetchingNextPage}
        setSearchTerm={setSearchTerm}
        updateError={updateError}
      />

      <ResetPasswordModal
        open={openUserPasswordResetModal}
        onClose={handleCloseUserPasswordResetModal}
        methods={methods}
        onSubmit={onResetPasswordSubmit}
        resetPasswordError={resetPasswordError}
      />
    </Box>
  );
};

export default UserManagementMain;
