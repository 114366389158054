import React from "react";
import InfoIcon from '@mui/icons-material/Info';
import ReportIcon from '@mui/icons-material/Report';
import { Chip, Tooltip } from "@mui/material";
import { yellow, red, blue } from "@mui/material/colors";

type LureLifespanChipProps = {
    lifespan: number | null;
    remaining: number | null;
};


const LureLifespanChip: React.FC<LureLifespanChipProps> = ({ lifespan, remaining }) => {
    const valuation = lifespan !== null && remaining !== null ? remaining > lifespan / 2 ? "info" : remaining < 7 ? "error" : "warning" : "warning";
    const iconType = valuation === "info" ? <InfoIcon /> : <ReportIcon />
    const label = remaining !== null ? `Lure remaining days: ${remaining}` : 'Lure remaining days: N/A'

    return (

        <Chip
            icon={iconType}
            label={label}
            variant="outlined"
            color={valuation}
        />
    );
};

export default LureLifespanChip