import { useQuery } from "@tanstack/react-query";
import { listAllImages, ListImageItem, ListImagesParams } from "queries/devices/images";
import { useCallback, useState } from "react";
import { getValidatorDeviceById } from "queries/validators";

export const useValidatorValidation = (deviceId: string) => {
  const [validatingImages, setValidatingImages] = useState<ListImageItem[]>([]);
  const [validationModelOpen, setValidationModelOpen] = useState(false);
  const [validationStartDate, setValidationStartDate] = useState<number | null | undefined>();

  const deviceQuery = useQuery({
    queryKey: ["validator-device-detail", deviceId],
    queryFn: () => getValidatorDeviceById(deviceId),
    onSuccess: (data) => {
      setValidationStartDate(data.validation_start_date);
    },
    enabled: true,
  });

  const notValidatedImagesParam: ListImagesParams = {
    page_size: 100,
    device__id: deviceId,
    ordering: "created_at",
    is_validated: false,
    is_visible: true,
    ...(validationStartDate ? { created_at__gte: validationStartDate } : {}),
  };

  const notValidatedImagesQuery = useQuery({
    queryKey: ["list-all-images", validationStartDate, deviceId],
    queryFn: async () => (await listAllImages(notValidatedImagesParam)).data,
    onError: (e) => {
      console.error("Failed to fetch images: ", e);
    },
    enabled: validationStartDate !== undefined && !!deviceId,
  });

  const handleFullPictureValidation = () => {
    setValidatingImages(notValidatedImagesQuery.data ?? []);
    setValidationModelOpen(true);
  };

  const handleStartValidation = useCallback((image: ListImageItem) => {
    setValidatingImages([image]);
    setValidationModelOpen(true);
  }, []);

  const handleStopValidation = useCallback(() => {
    setValidationModelOpen(false);
  }, []);

  return {
    validatingImages,
    validationModelOpen,
    listNotValidatedImagesLoading: notValidatedImagesQuery.isLoading,
    listNotValidatedImagesCount: notValidatedImagesQuery.data?.length ?? 0,
    handleStartValidation,
    handleStopValidation,
    handleFullPictureValidation,
    handleValidationDateChanged: () => deviceQuery.refetch,
  };
};

export default useValidatorValidation;
